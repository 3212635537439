footer {
  padding: 1rem 0;
  border-top: #EBF2F6 1px solid;
  font-size: 0.7rem;
  line-height: 1rem;
  color: #ffffff;
  text-align: center;
  background: #222;

  a {
    color: #BBC7CC;
    
    &:hover {
      color: #50585D;
    }
  }

  blockquote {
    color: #FF7700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;

    @media (max-width: $xsmall) {
      font-weight: 500;
    }
  
    .phrase {
      &::before {
        content: open-quote;
      }

      &::after {
        content: close-quote;
      }
    }

    .by {
      font-family: 'Ubuntu', 'sans-serif';
      color: #eeeeee;
      font-size: 0.9rem;
      line-height: 1.2rem;
      font-style: italic;
      font-weight: bold;
      margin: 1rem 0 0 0;

      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &:active,
    &:focus {
      outline: none;
      border: none;
    }
  }

  .tv {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 450px;
    min-width: 250px;
    margin: 0 auto;

    &__top {
      height: 100px;
      display: flex;
      align-items: flex-end;

      &__antenna {
        margin: 0 auto -5px auto;
        height: 20px;
        width: 80px;
        background-color: #fff;
        border-radius: 150px 150px 0 0;
        position: relative;

        &--left {
          background-color: #ffffff;
          transform: rotate(-20deg);
          height: 60px;
          width: 5px;
          position: absolute;
          bottom: 0;
          left: 20px;
        }

        &--right {
          background-color: #ffffff;
          transform: rotate(30deg);
          height: 80px;
          width: 5px;
          position: absolute;
          bottom: 0;
          right: 10px;
        }
      }
    }

    &__screen {
      background-color: #222;
      position: relative;
      padding: 4rem 2rem;
      border: solid 10px #fff;
      border-radius: 50% / 10%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 100px;

      &::before {
        content: '';
        position: absolute;
        top: 15%;
        bottom: 15%;
        right: 5%;
        left: 5%;
        border-radius: 5% / 50%;
        background-color: rgba(55,144,255,.05);
      }

      .pixel {
        position: absolute;
        width: 5px;
        height: 5px;
        background-color: #ffffff;
        right: 50px;
        bottom: 25%;
      }
    }

    @media (max-width: $xsmall) {
      visibility: hidden;
      min-width: initial;

      &__top {
        height: 0;
      }

      &__screen{
        padding: 0;

        .pixel {
          height: 0;
        }
      }

      .phrase,
      .by {
        visibility: visible;
        z-index: 3;
      }
    }
  }

  .copyright {
    margin-top: 3rem;

    @media (max-width: $xsmall) {
      margin-top: 0;
    }
  }
}