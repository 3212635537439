.modal {
  position: fixed;
  background-color: rgba(0,0,0,0.4);
  display:flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2;

  &.display-on {
    display: flex;
  }
  
  &.display-none {
    display: none;
  }
}

.modal-internal {
  background-color: white;
  height: 100%;
  width: 100%;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  

  a {
    color: #4a4a4a;
    transition: color ease 0.3s;
  }

  button {
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    padding: 15px 15px 10px 15px;
    white-space: nowrap;
    cursor: pointer;

    &:active,
    &:focus {
      outline: none;
      border: none;
    }

    &.arrow-left {
      position: absolute;
      left: 0;
    }

    &.arrow-right {
      position: absolute;
      right: 0;
    }

    &.back-button {
      position: absolute;
      right: 0;
      top: 20px;

      @media (max-width: $tablet) {
        top: 0;
      }
    }
  }

  .modal-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 65px;
    position: relative;

    .title {
      font-size: 18px;
      font-weight: 800;
      color: #444;
      margin-right: 1em;

      @media (min-width: $desktop + 1) {
        font-size: 24px;
      }
    }

    .years {
      color: #555;
      font-size: 12px;
      padding: 5px 0 15px 0;
    }

    .description {
      border-top: solid 1px #999;
      font-size: 14px;
      padding: 15px 0;
    }
  }

  .modal-image {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 50px 0 0 0;
    max-height: 300px;
    overflow: hidden;
    border-bottom: solid 3px #333;

    @media (min-width: $tablet + 1) {
      max-height: 650px;
      margin: 0;
    }

    img {
      width: 100%;
      max-width: 800px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }

  .links {
    font-size: 12px;
    padding-top: 10px;
    ul {
      list-style-type: none;
      padding: 0;
    }
  }

  @media (min-width: $desktop + 1) {
    height: initial;
    padding: 50px 0;
    width: 75%;
  }
}

.modal-nav-buttons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}