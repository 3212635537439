nav {
  position: fixed;
  z-index: 2;
  width: 80%;
  padding: 0 10%;
  height: 100%;
  max-height: 80px;
  background-color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 2px;
  font-family: 'Open Sans', sans-serif;

  @media (max-width: $extraLargeDesktop) {
    width: 80%;
    padding: 0 10%;
  }
  @media (max-width: $largeDesktop) {
    max-height: 80px;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: #ffffff;

    &:hover {
      color: #FF7700;
    }

    @media (max-width: $largeDesktop) {
      width: 100%;
      display: block;
    }
  }

  .nocturnal-nav-links {
    ul {
      list-style-type: none;
      display: flex;

      li {
        padding: 0 20px;
        text-transform: uppercase;
        color: #ffffff;
        cursor: pointer;
        transition: color ease 0.3s;

        &:hover {
          color: #FF7700;
        }


        @media (max-width: $largeDesktop) {
          text-transform: capitalize;
        }
      }
    }
  }
}

.nocturnal-hamburger {
  font-size: 2em;
  color: #ffffff;
  margin-top: -2.5px;
  display: none;
  cursor: pointer;

  @media (max-width: $largeDesktop) {
    display: initial;
    margin-top: -10px;
    flex-grow: 2;
  }
  @media (max-width: $tablet) {
    display: initial;
    margin-top: -5px;
  }
}

@media (max-width: $largeDesktop) {
  .nocturnal-links{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .nocturnal-nav-links {
      display: none;
      position: absolute;
      background-color:  #333;
      left: 0;
      top: 80px;
      width: 100%;
      text-align: center;
    
      ul {
        flex-direction: column;
        padding: 0;

        li {
          padding: 0.5em 0;
        }
      }

      &.mobile-open {
        display: initial;
      }
    }
  }
}