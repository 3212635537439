.nocturnal-education {
	line-height: 24px;

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;

		li {
			padding-bottom: 10px;
    	display: list-item;

    	div {
    		padding: 0.25em 0;
    	}

    	.title {
    		font-size: 15px;
    		font-weight: 800;
    	}
		}
	}

	.awards{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 20px 0 0 0;

		> div {
			padding: 1em;
		}

		@media (max-width: $desktop) {
			> div {
				padding: 1em 0;
			}
		}

		.sub-title{
	    font-style: italic;
	    margin-left: -1em;

	    @media (max-width: $desktop) {
	    	margin-left: initial;
	    }
		}
	}
}