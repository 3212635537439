header {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 1vmin);
  font-family: 'Oswald', sans-serif;
  text-align: center;
  text-transform: unset;
  letter-spacing: 2px;
  text-shadow: 0 1px 3px rgba(0,0,0,0.95);
  color: white;
  background: #303538 no-repeat center center;
  background-image: url('../../Components/Header/header-background-np.jpg');
  background-size: cover;
  position: relative;

  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(0, 0, 0, 0.3);
  }

  @media (max-width: $desktop) {
    min-height: 50vh;
  }

  h1 {
    z-index: 1;

    @media (max-width: $desktop) {
      font-size: 3em;
    }
    @media (max-width: $tablet) {
      font-size: 3.5em;
      padding: 0 2em;    
    }
  }
}