body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h2{
  text-rendering: optimizeLegibility;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-size: 1.75rem;
  line-height: 1.75rem;
  text-indent: -2px;

  @media (min-width: $desktop) {
    font-size: 2rem;
    line-height: 2rem;
  }
}

h3 {
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  margin-top: 3rem;

  @media (min-width: $desktop) {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}

main {

  section {
  	position: relative;
    margin: 5rem auto;
    word-break: break-word;
    hyphens: auto;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    padding: 0 5rem;
    line-height: 32px;

    ul {
	
      line-height: 25px;

		  li{
			  padding: 0.7em 0;
			}

      @media (max-width: $tablet) {
        padding: 0 1rem;
      }
		}

    @media (max-width: $tablet) {
      padding: 0 2rem;
    }
  }

  .nocturnal-smaller {
	  font-size: 14px;
  }
}