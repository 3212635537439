.nocturnal-work {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
    width: 100%;

    li {
      display: inline-block;
      padding: 0 3px 0 0;
      
      @media (max-width: $tablet) {
        width: 45%;
      }

      img {
        cursor: pointer;
        width: 100%;
      }
    }
  }
}