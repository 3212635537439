.nocturnal-intro {
  background-color:#222;
  color: #fff;
  top:0;
  padding: 60px 0 10px 0;
  text-align: center;
  width: 100%;
  height: 20%;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;

  &-desc {
    width:80%;
    margin:0 auto;
    text-align: justify;
    font-size: 15px;
    line-height: 35px;

    p {
      padding-top: 1rem;
    }

    a {
      color: #bbb;
      transition: color ease 0.5s;

      &:hover{
        color: #57A3E8;
      }
    }

    @media (max-width: $tablet) {
      width: 85%;
      text-align: left;
      line-height: 30px;
    }
  }
}

.nocturnal-social {
  padding: 0.5em 0;
  list-style: none;

  li {
    display:inline;
    padding: 0 0px;
  }

  img:hover {
    transition: background-color ease 0.3s;
    border-radius: 70%;
  }

  .icon- {   
    
    &twitter:hover {
      background-color:#00ACED;
    }
    
    &instagram:hover {
      background-color:#3F729B;
    }
    
    &flickr:hover {
      background-color:#0063DB;
    }

    &pinterest:hover {
      background-color:#CB2027;
    }

    &vimeo:hover {
      background-color:#44BBFF;
    }

    &linkedin:hover {
      background-color:#007FB1;
    }

  }
}